import { defineStore } from 'pinia'
import { ExplorerBlockWithTxs } from '@injectivelabs/sdk-ts'
import { HttpRequestException } from '@injectivelabs/exceptions'
import { indexerRestExplorerApi } from '@/app/Services'
import { BlockNotFoundException } from '@/app/exceptions/BlockNotFoundException'

type BlockStoreState = {
  block?: ExplorerBlockWithTxs
}

const initialStateFactory = () => ({
  block: undefined
})

const initialState = initialStateFactory()

export const state = () => ({
  block: initialState.block as ExplorerBlockWithTxs | undefined
})

export const useBlockStore = defineStore('block', {
  state: (): BlockStoreState => initialStateFactory(),
  actions: {
    async fetchBlock(blockHashHeight: string) {
      const blockStore = useBlockStore()
      const appStore = useAppStore()

      try {
        const block = await indexerRestExplorerApi.fetchBlock(blockHashHeight)
        const { transactions: txs } =
          await indexerRestExplorerApi.fetchTransactions({
            before: block.height,
            after: block.height,
            limit: 20
          })

        const blockWithTxsDetails = {
          ...block,
          txs: block.txs.map((transaction) => ({
            ...transaction,
            ...txs.find((tx) => tx.hash === transaction.hash)
          }))
        }

        blockStore.$patch({
          block: blockWithTxsDetails
        })
      } catch (e: any) {
        if (e instanceof HttpRequestException) {
          if (e.code === 404 || e.message.includes('object not found')) {
            appStore.$patch({
              searchParam: blockHashHeight
            })

            throw new BlockNotFoundException()
          }
        }
        throw e
      }
    }
  }
})
