import { getTime, set } from 'date-fns'
import { getEthereumAddress, getInjectiveAddress } from '@injectivelabs/sdk-ts'
import { MarketType } from '@injectivelabs/sdk-ui-ts'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { denomClientSync } from '../Services'
import { IS_DEVNET, IS_TESTNET } from './constants'
import { DOMEvent } from '@/types'

export const isTransactionFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()

  return (
    (valueTrimmed.toLocaleLowerCase().startsWith('0x') &&
      valueTrimmed.trim().length === 66) ||
    (!valueTrimmed.trim().toLocaleLowerCase().startsWith('0x') &&
      valueTrimmed.length === 64)
  )
}

export const isAddressFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()
  const token = denomClientSync.getDenomToken(value)

  if (
    (valueTrimmed.toLowerCase().startsWith('inj') &&
      valueTrimmed.length === 42) ||
    (token && token.erc20?.address)
  ) {
    try {
      return !!getEthereumAddress(
        token && token.erc20 ? token.erc20.address : value
      )
    } catch (error: any) {
      return false
    }
  }

  return false
}

export const isSubaccountFormat = (value: string): boolean =>
  value.toLowerCase().startsWith('0x') && value.length === 66

export const isBlockFormat = (value: number | string): boolean => {
  const numericValue = Number(value)

  return Number.isInteger(numericValue) && numericValue > 0
}

export const isBridgedFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()

  return (
    valueTrimmed.toLowerCase().startsWith('peggy') && valueTrimmed.length === 47
  )
}

export const isIbcFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()

  return (
    valueTrimmed.toLowerCase().startsWith('ibc') && valueTrimmed.length === 68
  )
}

export const isNativeFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()

  if (valueTrimmed.toLocaleLowerCase().startsWith('factory')) {
    try {
      const [, , address] = valueTrimmed.split('/')

      return !!getEthereumAddress(address)
    } catch (error: any) {
      return false
    }
  }

  const isNativeInjDenom =
    valueTrimmed.toLocaleLowerCase() === 'inj' && valueTrimmed.length === 3

  const isInsuranceFundDenom = valueTrimmed.startsWith('share')

  return isNativeInjDenom || isInsuranceFundDenom
}

export const isWasmCodeFormat = (value: number | string): boolean => {
  const numericValue = Number(value)

  return Number.isInteger(numericValue) && numericValue > 0
}

export const isEthereumAddressFormat = (value: string): boolean => {
  const valueTrimmed = value.trim()

  if (valueTrimmed.toLowerCase().startsWith('0x')) {
    try {
      return !!getInjectiveAddress(value)
    } catch (error: any) {
      return false
    }
  }

  return false
}

export const passInputValidation = (
  event: DOMEvent<HTMLInputElement>
): boolean => {
  if (!event.key) {
    return true
  }

  const pattern = /^[a-zA-Z0-9/-/_():.\s]*$/

  return pattern.test(event.key)
}

export const roundToNearestSecond = (timestamp: number) => {
  return getTime(
    set(timestamp, {
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    })
  )
}

export const getHubUrl = (): string => {
  if (IS_DEVNET) {
    return 'https://devnet.hub.injective.dev/'
  }

  if (IS_TESTNET) {
    return 'https://hub.injective.dev/'
  }

  return 'https://hub.injective.network/'
}

export const isInjectiveAddress = (value: string) => {
  try {
    return !!getEthereumAddress(value)
  } catch (error: any) {
    return false
  }
}

export const isSpotMarketBasedOnRoute = (
  route: RouteLocationNormalizedLoaded
) => {
  return !!route.name?.toString().includes(MarketType.Spot.toLowerCase())
}

export const isBinaryOptionsMarketBasedOnRoute = (
  route: RouteLocationNormalizedLoaded
) => {
  return !!route.name
    ?.toString()
    .includes(MarketType.BinaryOptions.toLowerCase())
}
