import { TokenService, TokenPrice } from '@injectivelabs/sdk-ui-ts'
import {
  DenomClient,
  ChainGrpcBankApi,
  ChainGrpcWasmApi,
  IndexerGrpcSpotApi,
  ChainGrpcStakingApi,
  IndexerGrpcAccountApi,
  IndexerRestExplorerApi,
  ChainGrpcDistributionApi,
  ChainGrpcInsuranceFundApi,
  IndexerGrpcDerivativesApi,
  IndexerRestSpotChronosApi,
  IndexerGrpcAccountPortfolioApi,
  IndexerRestDerivativesChronosApi,
  DenomClientSync
} from '@injectivelabs/sdk-ts'
import { CoinGeckoApi } from '@injectivelabs/token-utils'
import { NETWORK, CHAIN_ID, ENDPOINTS, COINGECKO_KEY } from './utils/constants'

export const indexerGrpcAccountPortfolioApi =
  new IndexerGrpcAccountPortfolioApi(ENDPOINTS.indexer)
export const indexerAccountApi = new IndexerGrpcAccountApi(ENDPOINTS.indexer)
export const indexerDerivativesApi = new IndexerGrpcDerivativesApi(
  ENDPOINTS.indexer
)
export const indexerSpotApi = new IndexerGrpcSpotApi(ENDPOINTS.indexer)
export const indexerRestExplorerApi = new IndexerRestExplorerApi(
  `${ENDPOINTS.indexer}/api/explorer/v1`
)

export const distributionApi = new ChainGrpcDistributionApi(ENDPOINTS.grpc)
export const stakingApi = new ChainGrpcStakingApi(ENDPOINTS.grpc)
export const bankApi = new ChainGrpcBankApi(ENDPOINTS.grpc)
export const wasmApi = new ChainGrpcWasmApi(ENDPOINTS.grpc)

export const insuranceFundsApi = new ChainGrpcInsuranceFundApi(ENDPOINTS.grpc)

export const indexerRestDerivativesChronosApi =
  new IndexerRestDerivativesChronosApi(
    `${ENDPOINTS.indexer}/api/chronos/v1/derivative`
  )

export const indexerRestSpotChronosApi = new IndexerRestSpotChronosApi(
  `${ENDPOINTS.indexer}/api/chronos/v1/spot`
)

export const tokenService = new TokenService({
  chainId: CHAIN_ID,
  network: NETWORK
})

const COINGECKO_OPTIONS = {
  apiKey: COINGECKO_KEY as string,
  baseUrl: COINGECKO_KEY
    ? 'https://pro-api.coingecko.com/api/v3'
    : 'https://api.coingecko.com/api/v3'
}

export const coinGeckoApi = new CoinGeckoApi(COINGECKO_OPTIONS)
export const tokenPrice = new TokenPrice(COINGECKO_OPTIONS)

export const denomClient = new DenomClient(NETWORK)
export const denomClientSync = new DenomClientSync(NETWORK)
