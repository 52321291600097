import { createI18n } from 'vue-i18n'
import en from '@/locales/en'
import { defineNuxtPlugin } from '#imports'

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  availableLocales: ['en'],
  messages: { en },
  useScope: 'global'
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(i18n)
})
