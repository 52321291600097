import { defineRule } from 'vee-validate'
import {
  isTransactionFormat,
  isAddressFormat,
  isBlockFormat,
  isBridgedFormat,
  isEthereumAddressFormat,
  isIbcFormat,
  isNativeFormat,
  isSubaccountFormat
} from '@/app/utils/helper'
import { subaccountToInj } from '@/app/utils/formatter'

const errorMessages = {
  account: () => "Your search doesn't match an account address format",
  subaccount: () => "Your search doesn't match a subaccount address format",
  block: () => "Your search doesn't match a block height format",
  blockHash: () => "Your search doesn't match a block hash format",
  bridged: () => "Your search doesn't match a bridged asset format",
  ibc: () => "Your search doesn't match an IBC asset format",
  native: () =>
    "Your search doesn't match a factory, insurance fund, or native inj asset format",
  transaction: () => "Your search doesn't match a transaction format",
  validGlobalSearchFormat: () =>
    "Your search doesn't match a block, transaction, asset, or address",
  validTradeHistorySearchFormat: () =>
    "Your search doesn't match an injective address or subaccountId",
  subaccountId: () => "Your search doesn't match a subaccountId format"
} as Record<string, any>

export const defineGlobalRules = () => {
  defineRule('etherAccount', (value: string) => {
    if (!isEthereumAddressFormat(value)) {
      return errorMessages.account()
    }

    return true
  })

  defineRule('account', (value: string) => {
    if (!isAddressFormat(value)) {
      return errorMessages.account()
    }

    return true
  })

  defineRule('subaccount', (value: string) => {
    if (!isSubaccountFormat(value)) {
      return errorMessages.subaccount()
    }

    return true
  })

  defineRule('block', (value: string) => {
    if (!isBlockFormat(value)) {
      return errorMessages.block()
    }

    return true
  })

  defineRule('blockHash', (value: string) => {
    if (!isTransactionFormat(value)) {
      return errorMessages.blockHash()
    }

    return true
  })

  defineRule('bridged', (value: string) => {
    if (!isBridgedFormat(value)) {
      return errorMessages.bridged()
    }

    return true
  })

  defineRule('ibc', (value: string) => {
    if (!isIbcFormat(value)) {
      return errorMessages.ibc()
    }

    return true
  })

  defineRule('native', (value: string) => {
    if (!isNativeFormat(value)) {
      return errorMessages.native()
    }

    return true
  })

  defineRule('transaction', (value: string) => {
    if (!isTransactionFormat(value)) {
      return errorMessages.transaction()
    }

    return true
  })

  defineRule('subaccountId', (value: string) => {
    if (!subaccountToInj(value)) {
      return errorMessages.subaccountId()
    }

    return true
  })

  defineRule('validTradeHistorySearchFormat', (value: string) => {
    if (!value) {
      return true
    }

    const condition =
      value.toLowerCase().startsWith('0x') ||
      value.toLowerCase().startsWith('inj')
    if (!condition) {
      return errorMessages.validTradeHistorySearchFormat()
    }

    return true
  })

  defineRule('validGlobalSearchFormat', (value: string) => {
    if (!value) {
      return true
    }

    const condition =
      value.toLowerCase().startsWith('0x') ||
      value.toLowerCase().startsWith('inj') ||
      !Number.isNaN(Number(value)) ||
      // tx & block hash without prefix
      (value.length === 64 && !value.toLowerCase().startsWith('0x'))

    if (!condition) {
      return errorMessages.validGlobalSearchFormat()
    }

    return true
  })
}

export default defineNuxtPlugin(() => {
  defineGlobalRules()
})
