import { format, formatDistanceToNow, parseJSON } from 'date-fns'
import {
  Token,
  IbcToken,
  TokenType,
  isIbcTokenCanonical
} from '@injectivelabs/token-metadata'
import {
  getTokenLogoFromTokenType,
  getTokenLogoWithVendorPathPrefix
} from '@injectivelabs/sdk-ui-ts'
import { getInjectiveAddress } from '@injectivelabs/sdk-ts'
import { TIME_AGO_DATE_FORMAT } from '@/app/utils/constants'
import { MetaType } from '@/types'

export const shortenTimestamp = (timestamp: string): string =>
  timestamp
    .replace('minutes', 'mins')
    .replace('minute', 'min')
    .replace('seconds', 'secs')
    .replace('second', 'sec')

export const formatTickSizeFromDecimals = (decimals: number): string => {
  return `0.${new Array(decimals).join('0')}1`
}

export const formatToPercentage = (value: number | string): string => {
  const valueToNumber = !isNaN(Number(value)) ? Number(value) : 0

  return `${valueToNumber * 100}`
}

export const formatMeta = (metaType: MetaType) => {
  return {
    title: getTitle(metaType),
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: getDescription(metaType)
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: getDescription(metaType)
      },
      {
        hid: 'twitter:description',
        property: 'twitter:description',
        content: getDescription(metaType)
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: getTitle(metaType)
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: getTitle(metaType)
      },
      {
        hid: 'twitter:title',
        property: 'twitter:title',
        content: getTitle(metaType)
      }
    ]
  }
}

export const getTitle = (metaType: MetaType): string => {
  if (
    [
      MetaType.Blocks,
      MetaType.Block,
      MetaType.Transactions,
      MetaType.Transaction
    ].includes(metaType)
  ) {
    return `Injective ${metaType} Explorer - Visualize and search for ${metaType.toLowerCase()} on the Injective Chain`
  }

  if (
    [
      MetaType.Contracts,
      MetaType.Contract,
      MetaType.Codes,
      MetaType.Code
    ].includes(metaType)
  ) {
    return `Injective ${metaType} Explorer - Visualize cosmwasm smart contracts on the Injective Chain`
  }

  return 'Injective Exchanges Explorer - Visualize all exchanges data build on Injective Chain'
}

export const getDescription = (metaType: MetaType): string => {
  if (
    [
      MetaType.Blocks,
      MetaType.Block,
      MetaType.Transactions,
      MetaType.Transaction
    ].includes(metaType)
  ) {
    return `The Injective ${metaType} Explorer enables to search addresses, blocks, transactions, exchange data, trades, tokens, validators, cosmwasm smart contracts over the Injective Chain.`
  }

  if ([MetaType.Contracts, MetaType.Contract].includes(metaType)) {
    return `The Injective ${metaType} Explorer enables to find cosmwasm smart contracts instances over the Injective Chain.`
  }

  if ([MetaType.Codes, MetaType.Code].includes(metaType)) {
    return `The Injective ${metaType} Explorer enables to find cosmwasm smart contracts codes over the Injective Chain.`
  }

  return 'The Injective Exchanges Explorer provides information all the exchanges build on top of Injective Chain.'
}

export const formatTimestamp = (timestamp: string | number) => {
  const date = parseJSON(timestamp)

  return `${formatDistanceToNow(date, {
    addSuffix: true
  })} (${format(date, TIME_AGO_DATE_FORMAT)})`
}

export const getTokenLogo = (token: Token): string => {
  const { tokenType, logo } = token

  const ibcToken = token as IbcToken

  if (!logo) {
    return getTokenLogoFromTokenType(tokenType)
  }

  if (
    (tokenType !== TokenType.Ibc || isIbcTokenCanonical(ibcToken)) &&
    tokenType !== TokenType.Native
  ) {
    return getTokenLogoWithVendorPathPrefix(logo)
  }

  if (token.denom.includes('factory')) {
    return `/tokens-logo/${token.logo}`
  }

  return getTokenLogoFromTokenType(tokenType)
}

export const parseRouteQuery = (query = ''): string =>
  query.trim().toLowerCase()

export const subaccountToInj = (address: string): string => {
  const ethAddress = address.slice(0, 42)

  if (address.length !== 66) {
    return ''
  }

  return getInjectiveAddress(ethAddress)
}

export const formatToBase64 = (value: string) => {
  const [encodedString] = Buffer.from(value).toString('base64').split('=')

  return encodedString
}

export const formatFromBase64 = (value: string) => {
  return Buffer.from(value, 'base64').toString('ascii')
}
