import { I18nMessageFunction } from './../../types'

export default {
  contract: {
    address: 'Address',
    admin: 'Admin',
    amount: 'Amount',
    balance: 'Balance',
    balances: 'Balances',
    codeId: 'Code ID',
    contract: 'Contract',
    contractName: 'Contract name',
    created: 'Created',
    creationDate: 'Creation date',
    creator: 'Creator',
    decimal: 'Decimal',
    denom: 'Denom',
    executed: 'Executed',
    fee: 'Fee',
    funds: 'Funds',
    height: 'Height',
    initMsg: 'InitMsg',
    lastExecuted: 'Last executed',
    lastExecutedAt: 'Last executed at',
    result: 'Result',
    smartContract: 'Smart Contract',
    smartContractDetails: 'Smart Contract details',
    smartContracts: 'Smart Contracts',
    time: 'Time',
    tokenInfo: 'Token info',
    txHash: 'Tx Hash',
    type: 'Type',
    newContracts: 'New Contracts',
    notFoundTitle: ({ named }: I18nMessageFunction) =>
      `Sorry, We are unable to locate this contract on ${named('network')}:`,
    notFoundDescription: 'Please try again.',
    invalidTitle: 'Oops! The following contract address format is incorrect:',
    invalidDescription: 'Please re-enter the contract address correctly.',
    noInitMsg: 'No Init Msg'
  }
}
