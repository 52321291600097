import block from './en/block'
import assets from './en/assets'
import account from './en/account'
import markets from './en/markets'
import contract from './en/contract'
import wasmCode from './en/wasmCode'
import transaction from './en/transaction'
import tradeHistory from './en/trade-history'
import featuredDapps from './en/featured-dapps'
import { I18nMessageFunction, NetworkErrorType } from './../types'

export default {
  ...block,
  ...assets,
  ...account,
  ...markets,
  ...contract,
  ...wasmCode,
  ...transaction,
  ...tradeHistory,
  ...featuredDapps,

  common: {
    via: 'via',
    code: 'Code',
    sent: 'sent',
    dApps: 'DApps',
    block: 'Block',
    assets: 'Assets',
    blocks: 'Blocks',
    wasmCode: 'Code',
    status: 'Status',
    failed: 'Failed',
    inputs: 'Inputs',
    outputs: 'Outputs',
    account: 'Account',
    success: 'Success',
    markets: 'Markets',
    chainId: 'Chain Id',
    viewAll: 'View all',
    explorer: 'Explorer',
    received: 'received',
    contracts: 'Contracts',
    timestamp: 'Timestamp',
    transaction: 'Transaction',
    transactions: 'Transactions',
    analytics: 'Analytics',
    activity: 'Activity',
    all: 'All'
  },

  navigation: {
    hub: 'Hub',
    exchange: 'Exchange',
    validators: 'Validators'
  },

  network: {
    terra: 'Terra',
    evmos: 'Evmos',
    axelar: 'Axelar',
    cosmos: 'Cosmos',
    osmosis: 'Osmosis',
    ethereum: 'Ethereum',
    chihuahua: 'ChiHuaHua',
    persistence: 'Persistence',
    injective: 'Injective Chain'
  },

  toast: {
    tradeIdCopied: 'Trade ID copied to your clipboard',
    addressCopied: 'Address copied to your clipboard',
    denomHashCopied: 'Denom hash copied to your clipboard',
    blockHashCopied: 'Block hash copied to your clipboard',
    txHashCopied: 'Transaction hash copied to your clipboard',
    blockNumberCopied: 'Block height copied to your clipboard',
    contractCopied: 'Contract address copied to your clipboard',
    contractInitMsgCopied: 'Contract initMsg copied to your clipboard',
    contractFundCopied: 'Contract funds details copied to your clipboard',
    contractTokenInfoCopied: 'Contract token info copied to your clipboard'
  },

  networkError: {
    [NetworkErrorType.AccountPageBankBalances]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.AccountPageCW20Balances]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.AccountPageSubaccountBalances]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.AccountPageTransactions]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.BlockDetailsPage]:
      'Network error: Unable to fetch block.',
    [NetworkErrorType.BlocksPageBlocks]:
      'Network error: Unable to fetch blocks.',
    [NetworkErrorType.ContractDetailsPage]:
      'Network error: Unable to fetch contract.',
    [NetworkErrorType.ContractsPageContracts]:
      'Network error: Unable to fetch contracts.',
    [NetworkErrorType.ContractsPageAccountsBalance]:
      'Network error: Unable to fetch balances.',
    [NetworkErrorType.ContractPageTransactions]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.TransactionDetailsPage]:
      'Network error: Unable to fetch transaction.',
    [NetworkErrorType.TransactionsPageTransactions]:
      'Network error: Unable to fetch transactions.',
    [NetworkErrorType.WasmCodePageCodes]:
      'Network error: Unable to fetch codes.',
    [NetworkErrorType.WasmCodeDetailsPage]:
      'Network error: Unable to fetch code.'
  },

  noRecord: {
    [NetworkErrorType.AccountPageBankBalances]: 'No balances found.',
    [NetworkErrorType.AccountPageCW20Balances]: 'No balances found.',
    [NetworkErrorType.AccountPageSubaccountBalances]: 'No balances found.',
    [NetworkErrorType.AccountPageTransactions]: 'No transactions found.',
    [NetworkErrorType.BlocksPageBlocks]: 'No blocks found.',
    [NetworkErrorType.ContractsPageContracts]: 'Contracts coming soon!',
    [NetworkErrorType.GenericNoRecordsFound]: 'No records found.',
    [NetworkErrorType.TransactionsPageTransactions]: 'No transactions found.',
    [NetworkErrorType.WasmCodePageCodes]: 'Contracts coming soon!',
    [NetworkErrorType.TradeHistory]: 'No transaction history found.',
    noResultsFound: ({ named }: I18nMessageFunction) =>
      `No results for ${named('search')}`
  },

  pagination: {
    showRows: 'Show rows',
    showCountOutOfTotal: ({ named }: I18nMessageFunction) =>
      `Showing ${named('from')} - ${named('to')} out of ${named('totalCount')}`
  },
  // mics
  FilterClearFilterBtn: 'Clear filters',
  FilterApplyBtn: 'Filter',
  notFound: '404 Not Found',
  searchPlaceHolder: 'Search for a transaction, block or address',

  // Footer
  FooterCopyRightMsg: ({ named }: I18nMessageFunction) =>
    `Copyright © ${named('year')} Open DeFi Foundation. All rights reserved.`,

  // Maintenance:
  maintenanceHeader: 'Ongoing Upgrade',
  maintenanceSubHeader:
    'We are working on improving your experience. We will be back soon.',
  maintenanceBody: ''
}
