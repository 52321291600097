import validate from "/root/injective/injective-explorer/node_modules/nuxt/dist/pages/runtime/validate.js";
import trailing_45slash_45global from "/root/injective/injective-explorer/middleware/trailing-slash.global.ts";
export const globalMiddleware = [
  validate,
  trailing_45slash_45global
]
export const namedMiddleware = {
  "account-search": () => import("/root/injective/injective-explorer/middleware/account-search.ts"),
  "block-search": () => import("/root/injective/injective-explorer/middleware/block-search.ts"),
  "contract-search": () => import("/root/injective/injective-explorer/middleware/contract-search.ts"),
  maintenance: () => import("/root/injective/injective-explorer/middleware/maintenance.ts"),
  "transaction-search": () => import("/root/injective/injective-explorer/middleware/transaction-search.ts"),
  "wasm-code-search": () => import("/root/injective/injective-explorer/middleware/wasm-code-search.ts")
}