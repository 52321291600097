import { I18nMessageFunction } from './../../types'

export default {
  wasmCode: {
    checksum: 'Checksum',
    codeDetail: 'Code detail',
    codeId: 'Code ID',
    codeSchema: 'Code Schema',
    codeViewer: 'Code Viewer',
    contract: 'Contract',
    creationDate: 'Creation Date',
    creator: 'Creator',
    id: 'Id',
    instances: 'Instances',
    newCodes: 'New Codes',
    permission: 'Permission',
    permittedAddress: 'Permitted Address',
    proposalId: 'Proposal ID',
    txHash: 'Tx Hash',
    type: 'Type',
    version: 'Version',
    wasmCode: 'Wasm Code',
    wasmCodeDetails: 'Wasm Code Details',
    wasmCodes: 'Wasm codes',
    notFoundTitle: ({ named }: I18nMessageFunction) =>
      `Sorry, We are unable to locate this code on ${named('network')}:`,
    notFoundDescription: 'Please try again.',
    invalidTitle: 'Oops! The following code id format is incorrect:',
    invalidDescription: 'Please re-enter the code id correctly.'
  }
}
