import plugin_vue3_A0OWXRrUgq from "/root/injective/injective-explorer/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/injective/injective-explorer/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/root/injective/injective-explorer/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_M7DKUOwKp5 from "/root/injective/injective-explorer/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_jmwsqit4Rs from "/root/injective/injective-explorer/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/root/injective/injective-explorer/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/root/injective/injective-explorer/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/root/injective/injective-explorer/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/root/injective/injective-explorer/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/root/injective/injective-explorer/node_modules/nuxt/dist/app/plugins/payload.client.js";
import buffer_bieIDf9x4O from "/root/injective/injective-explorer/nuxt-config/buffer.ts";
import handlers_oZWLY9pUCB from "/root/injective/injective-explorer/plugins/handlers.ts";
import i18n_VfGcjrvSkj from "/root/injective/injective-explorer/plugins/i18n.ts";
import store_9xNuDHGAVU from "/root/injective/injective-explorer/plugins/store.ts";
import vee_validate_KcKlKmvCrJ from "/root/injective/injective-explorer/plugins/vee-validate.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  vueuse_head_polyfill_M7DKUOwKp5,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  buffer_bieIDf9x4O,
  handlers_oZWLY9pUCB,
  i18n_VfGcjrvSkj,
  store_9xNuDHGAVU,
  vee_validate_KcKlKmvCrJ
]