import { BigNumber, BigNumberInBase } from '@injectivelabs/utils'
import {
  Network,
  getNetworkEndpoints,
  NetworkEndpoints
} from '@injectivelabs/networks'
import { ChainId, EthereumChainId } from '@injectivelabs/ts-types'
import { NetworkType } from './../../types/network'

const isProduction = process.env.NODE_ENV === 'production'
const isWebpack = process.env.BUILDER_TYPE === 'webpack'

export const IS_DEVELOPMENT: boolean = process.env.NODE_ENV === 'development'
export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production'

export const env = {
  VITE_ENV: isWebpack ? process.env.VITE_ENV : import.meta.env.VITE_ENV,
  VITE_NAME: isWebpack ? process.env.VITE_NAME : import.meta.env.VITE_NAME,
  VITE_BASE_URL: isWebpack
    ? process.env.VITE_BASE_URL
    : import.meta.env.VITE_BASE_URL,
  VITE_NETWORK: (isWebpack
    ? process.env.VITE_NETWORK
    : import.meta.env.VITE_NETWORK) as string,
  VITE_CHAIN_ID: isWebpack
    ? process.env.VITE_CHAIN_ID
    : import.meta.env.VITE_CHAIN_ID,
  VITE_ETHEREUM_CHAIN_ID: isWebpack
    ? process.env.VITE_ETHEREUM_CHAIN_ID
    : import.meta.env.VITE_ETHEREUM_CHAIN_ID,
  VITE_METRICS_ENABLED: isWebpack
    ? process.env.VITE_METRICS_ENABLED
    : import.meta.env.VITE_METRICS_ENABLED,

  VITE_INDEXER_API_ENDPOINT: isWebpack
    ? process.env.VITE_INDEXER_API_ENDPOINT
    : import.meta.env.VITE_INDEXER_API_ENDPOINT,
  VITE_SENTRY_GRPC_ENDPOINT: isWebpack
    ? process.env.VITE_SENTRY_GRPC_ENDPOINT
    : import.meta.env.VITE_SENTRY_GRPC_ENDPOINT,
  VITE_SENTRY_HTTP_ENDPOINT: isWebpack
    ? process.env.VITE_SENTRY_HTTP_ENDPOINT
    : import.meta.env.VITE_SENTRY_HTTP_ENDPOINT
}

export const IS_MAINNET_STAGING: boolean = env.VITE_ENV === 'staging'
export const NETWORK: Network = (env.VITE_NETWORK as Network) || Network.Devnet
export const IS_DEVNET = [
  Network.Devnet,
  Network.Devnet1,
  Network.Devnet2,
  Network.Local
].includes(NETWORK)
export const IS_TESTNET = [
  Network.Testnet,
  Network.TestnetOld,
  Network.TestnetK8s
].includes(NETWORK)

const MAINNET_CHAIN_ID = 'injective-1'
const TESTNET_CHAIN_ID = 'injective-888'
const DEVNET_CHAIN_ID = 'injective-777'

export const NETWORK_CHAIN_ID_DISPLAY: Record<string, any> = {
  [Network.Mainnet]: MAINNET_CHAIN_ID,
  [Network.MainnetK8s]: MAINNET_CHAIN_ID,
  [Network.MainnetLB]: MAINNET_CHAIN_ID,
  [Network.Public]: MAINNET_CHAIN_ID,
  [Network.Staging]: MAINNET_CHAIN_ID,
  [Network.Testnet]: TESTNET_CHAIN_ID,
  [Network.TestnetK8s]: TESTNET_CHAIN_ID,
  [Network.TestnetOld]: TESTNET_CHAIN_ID,
  [Network.Devnet]: DEVNET_CHAIN_ID,
  [Network.Devnet1]: DEVNET_CHAIN_ID,
  [Network.Devnet2]: DEVNET_CHAIN_ID,
  [Network.Local]: DEVNET_CHAIN_ID
}

export const NETWORK_BASE_URL: Record<string, any> = {
  [Network.Mainnet]: 'https://explorer.injective.network/',
  [Network.Public]: 'https://explorer.injective.network/',
  [Network.MainnetK8s]: 'https://explorer.injective.network/',
  [Network.Staging]: 'https://explorer.injective.network/',
  [Network.MainnetLB]: 'https://explorer.injective.network/',
  [Network.Testnet]: 'https://testnet.explorer.injective.network/',
  [Network.TestnetK8s]: 'https://testnet.explorer.injective.network/',
  [Network.TestnetOld]: 'https://testnet.explorer.injective.network/',
  [Network.Devnet]: 'https://devnet.explorer.injective.dev/',
  [Network.Devnet1]: 'https://devnet-1.explorer.injective.dev/',
  [Network.Devnet2]: 'https://devnet-2.explorer.injective.dev/',
  [Network.Local]: 'https://devnet.explorer.injective.dev/'
}

const NETWORK_DISPLAY: Record<string, any> = {
  [Network.Mainnet]: Network.Mainnet,
  [Network.MainnetK8s]: Network.Mainnet,
  [Network.Public]: Network.Mainnet,
  [Network.Staging]: Network.Mainnet,
  [Network.Public]: Network.Mainnet,
  [Network.Testnet]: Network.Testnet,
  [Network.TestnetK8s]: Network.Testnet,
  [Network.TestnetOld]: Network.Testnet,
  [Network.Devnet]: Network.Devnet,
  [Network.Devnet1]: Network.Devnet,
  [Network.Local]: Network.Devnet
}

export const MAINNET: NetworkType = {
  display: Network.Mainnet,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : Network.Mainnet,
  baseUrl: NETWORK_BASE_URL[Network.Mainnet]
}

export const DEVNET: NetworkType = {
  display: Network.Devnet,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : Network.Devnet,
  baseUrl: NETWORK_BASE_URL[Network.Devnet]
}

export const TESTNET: NetworkType = {
  display: Network.Testnet,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : Network.Testnet,
  baseUrl: NETWORK_BASE_URL[Network.Testnet]
}

const defaultNetwork = env.VITE_ENV
  ? IS_DEVNET
    ? DEVNET.network
    : IS_TESTNET
    ? TESTNET.network
    : MAINNET.network
  : NETWORK_DISPLAY[env.VITE_NETWORK]

const defaultDisplay = env.VITE_ENV
  ? IS_DEVNET
    ? DEVNET.display
    : IS_TESTNET
    ? TESTNET.display
    : MAINNET.display
  : NETWORK_DISPLAY[env.VITE_NETWORK]

const defaultBaseUrl = env.VITE_ENV
  ? IS_DEVNET
    ? DEVNET.baseUrl
    : IS_TESTNET
    ? TESTNET.baseUrl
    : MAINNET.baseUrl
  : NETWORK_BASE_URL[env.VITE_NETWORK]

export const DEFAULT_NETWORK: NetworkType = {
  display: defaultDisplay,
  network: Object.values(Network).includes(NETWORK) ? NETWORK : defaultNetwork,
  baseUrl: defaultBaseUrl
} as NetworkType

export const NETWORKS: NetworkType[] = IS_DEVNET
  ? [MAINNET, DEVNET, TESTNET]
  : [MAINNET, TESTNET]

const endpoints = getNetworkEndpoints(NETWORK)
export const ENDPOINTS: NetworkEndpoints = {
  ...endpoints,
  grpc: (env.VITE_SENTRY_GRPC_ENDPOINT as string) || endpoints.grpc,
  rest: (env.VITE_SENTRY_HTTP_ENDPOINT as string) || endpoints.rest,
  indexer: (env.VITE_INDEXER_API_ENDPOINT as string) || endpoints.indexer
}

export const CHAIN_ID: ChainId = (
  env.VITE_CHAIN_ID
    ? env.VITE_CHAIN_ID
    : IS_TESTNET
    ? ChainId.Testnet
    : IS_DEVNET
    ? ChainId.Devnet
    : ChainId.Mainnet
) as ChainId

export const ETHEREUM_CHAIN_ID: EthereumChainId = env.VITE_ETHEREUM_CHAIN_ID
  ? parseInt(env.VITE_ETHEREUM_CHAIN_ID.toString())
  : parseInt(
      (IS_TESTNET || IS_DEVNET
        ? EthereumChainId.Goerli
        : EthereumChainId.Mainnet
      ).toString()
    )

export const COINGECKO_KEY = isWebpack
  ? process.env.VITE_COINGECKO_KEY
  : import.meta.env.VITE_COINGECKO_KEY

export const ALCHEMY_KEY = isProduction
  ? process.env.VITE_ALCHEMY_KEY
  : import.meta.env.VITE_ALCHEMY_KEY

export const ALCHEMY_GOERLI_KEY = isProduction
  ? process.env.VITE_ALCHEMY_GOERLI_KEY
  : import.meta.env.VITE_ALCHEMY_GOERLI_KEY

export const alchemyRpcEndpoint =
  IS_TESTNET || IS_DEVNET
    ? `https://eth-goerli.alchemyapi.io/v2/${ALCHEMY_GOERLI_KEY}`
    : `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`

export const INJ_DENOM = 'inj'

export const DEFAULT_MARKET = 'BTC/USDT PERP'

export const ACTIVE = 'active'
export const ETH_COIN_GECKO_ID = 'ethereum'
export const USDT_COIN_GECKO_ID = 'tether'
export const UST_COIN_GECKO_ID = 'terrausd'
export const INJ_COIN_GECKO_ID = 'injective-protocol'
export const ZERO_TO_STRING = '0'
export const UI_DEFAULT_DECIMAL_PLACES = 3
export const TOKEN_HOLDINGS_DECIMAL_PLACES = 3
export const UI_DEFAULT_USD_DECIMAL_PLACES = 2
export const TOKEN_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -TOKEN_HOLDINGS_DECIMAL_PLACES
)
export const UI_MINIMAL_DECIMAL_PLACES = 6
export const UI_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -UI_MINIMAL_DECIMAL_PLACES
)
export const BIG_NUMBER_ROUND_DOWN_MODE = BigNumberInBase.ROUND_DOWN
export const PAGINATION_TOTAL_PAGE_LIMIT = 10000
export const DEFAULT_PAGINATION_TOTAL_COUNT = 1000000
export const UI_MAX_PAGINATION_LIMIT_COUNT = 50
export const UI_DEFAULT_PAGINATION_LIMIT_COUNT = 20
export const DEFAULT_FUNDING_RATE_DECIMALS = 4
export const TIME_AGO_DATE_FORMAT = "MMM-dd-yyyy HH:mm:ss aa 'UTC'xxx"
export const SMART_CONTRACT_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'

export const DATE_PICKER_FORMAT = 'dd MMM yy'
