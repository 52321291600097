import { defineStore } from 'pinia'
import { Status, StatusType } from '@injectivelabs/utils'
import { DEFAULT_NETWORK } from '@/app/utils/constants'
import { NetworkType } from '@/types/network'
import { SearchStatusType, NetworkErrorType } from '@/types/enum'

type AppStoreState = {
  network: NetworkType
  searchStatus?: SearchStatusType
  searchParam?: number | string
  status: Status
  networkErrorTypes: NetworkErrorType[]
}

const initialStateFactory = () => ({
  network: DEFAULT_NETWORK,
  searchStatus: undefined,
  searchParam: undefined,
  status: new Status(StatusType.Idle),
  networkErrorTypes: []
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  actions: {
    async init() {
      //
    },

    setGlobalStatusToLoading() {
      const appStore = useAppStore()

      appStore.$patch({
        status: new Status(StatusType.Loading)
      })
    }
  }
})
