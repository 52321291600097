import { I18nMessageFunction } from './../../types'

export default {
  featuredDapps: {
    here: ' here.',
    defi: 'DeFi',
    tools: 'Tools',
    exchange: 'Exchange',
    openDapp: ({ named }: I18nMessageFunction) => `Open ${named('name')}`,
    viewDapps: 'View dApps',
    allCategory: 'All Category',
    socialMedia: 'Social Media',
    featuredDapps: 'Featured dApps',
    buildOnInjective: 'Build on Injective',
    exploreDapps: 'Explore Injective dApps',
    injectiveContract: 'Injective Contract',
    submitYourOwnProject: 'Submit your own project',
    dappsExplained:
      'Injective has built the largest and fastest growing Web3 financial ecosystem, explore the featured builders working together to create a truly free and inclusive financial world.'
  }
}
